<template>
  <div class="coupon-item " :class="conponType==0?'coupon-item-d':'coupon-item-dgray'">
    <div class="c-type">
      <div class="c-price">
        <em>¥</em>
        <strong>{{couponItem.couponvalue}}</strong>
        <span class="type">优惠券</span>
      </div>
      <div class="c-limit">{{couponItem.threshold_notice}}</div>
      <div class="c-time">有效期至{{couponItem.enddate}}</div>
      <div class="c-type-top"></div>
      <div class="c-type-bottom"></div>
    </div>

    <div class="c-msg">
      <div class="c-range">
        <div class="range-item">
          <span class="label">限平台：</span>
          <span class="txt">全平台</span>
        </div>

        <div class="range-item">
          <span class="label">券编号：</span>
          <span class="txt">{{couponItem.couponcode}}</span>
        </div>
      </div>

      <div class="op-btns">
        <a class="btn"><span class="txt">立即使用</span><b></b></a>
      </div>

      
    </div>
  </div>
</template>
<script>
	export default {
		props:{
			couponItem: { 
				type: Object ,
			
			},
      conponType:{
        type:Number
      }

		
		},
		data() {
			return {
			
			}
		},
	
		methods: {

			tabClick(i){
				
				if(this.value!=i){
					this.$emit("input",i);
					this.$emit("change",i);
				}
			},
		
		},
		mounted() {
			
		}
	}
</script>

<style lang="less" scoped>
.coupon-item {
  width: 244px;
  margin: 14px 10px 20px;
  box-shadow: 1px 3px 11px #e0e0e0;
  .c-type {
    position: relative;
    width: 100%;
    // height: 129px;
    padding: 30px 0 10px;
    overflow: hidden;
    color: #fff;
    text-align: center;
    background-color: #1ca3f2;
  }
  .c-price {
    position: relative;
    margin: 0 0 5px;
    text-align: center;
    font-weight: 400;
    em {
      font-family: verdana;
      font-size: 24px;
      position: relative;
      top: -11px;
      font-style: normal;
    }
    strong {
      line-height: 45px;
      font-size: 40px;
      font-family: Arial;
    }
    .type {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      text-align: text-bottom;
      color: #ddeefe;
    }
  }
  .c-limit {
    height: 44px;
    line-height: 22px;
    overflow: hidden;
    margin: 0 0 5px;
    font-size: 12px;
  }
  .c-time {
    color: #ddeefe;
  }
  .c-type-top {
    position: absolute;
    width: 40px;
    height: 13px;
    top: 0;
    left: 50%;
    margin-left: -23px;
    background: url(//misc.360buyimg.com/user/myjd-2015/css/i/coupon20160715.png)
      0 -272px no-repeat;
  }
  .c-type-bottom {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: url(//misc.360buyimg.com/user/myjd-2015/css/i/coupon20160715.png) -3px -296px
      no-repeat;
    background-position: 2px -302px;
  }
  .c-msg {
    height: 180px;
    font-size: 14px;
    // line-height: 30px;
    padding: 30px 10px 0;
    background: #f9f9f9;
    overflow: visible;
  }
  .range-item {
    display: inline-block;
    display: block;
    margin: 0 0 10px;
    &:after {
      content: "";
      height: 0;
      line-height: 0;
      display: block;
      visibility: hidden;
      clear: both;
    }
    .label {
      float: left;
      width: 60px;
      display: inline;
      text-align: right;
    }
    .txt {
      float: left;
      width: 144px;
      color: #999;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .op-btns {
    margin-top: 30px;
    text-align: center;
    .btn {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: 119px;
      height: 31px;
      line-height: 31px;
      border: 1px solid #1ca3f2;
      color: #1ca3f2;
      border-radius: 15px;
      vertical-align: middle;
      transition: all 0.4s;
      &:hover {
        color: #fff;
        background: #1ca3f2;
      }
    }
  }
  &.coupon-item-dgray{
      .c-type {
        background-color: #c3c3c3;
         .c-price{
             .type {
                color: #868686;
            }
        
         } 
         .c-time {
    color: #868686;
}


    }
    .c-type-bottom {
    background-position: 0 -316px;
}
    .op-btns {
        display: none;
    }


  }
}
</style>