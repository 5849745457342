<template>
    <div>
        <h2><strong>我的优惠券</strong></h2>
        <!-- <div class="business-nav-box">
            <ul class="business-nav">
                <li class="active">有效券</li>
                <li>失效券</li>
            </ul>
            <div>
                
            </div>
        </div> -->
        <me-tabs v-model="tabIndex" :tabs="tabs" @change="tabChange">
            <template v-slot:right>
                <div class="JumpCoupon" @click="jumpCoupon"><i class="iconfont iconyouhuiquan"></i>购买优惠券<em>+</em></div>
            </template>
        </me-tabs>
        <div class="coupon-List">
            
            <a-list :grid="{ gutter: 16, column: 6 }"  item-layout="horizontal" :loading='loading' :data-source="couponlist" :pagination="{ ...paginationProps, current: currentPage }">
                <a-list-item slot="renderItem" slot-scope="item">
                    <coupon-item  :couponItem='item' :conponType='conponType'></coupon-item>
                </a-list-item>
            </a-list>
        </div>
    </div>    
</template>
<script>

import couponItem from "./components/couponItem";
import meTabs from "@/components/meTabs";
import { getCouponlist } from "@/api/manage";


export default {
  components: {

    couponItem,
    meTabs
  },
  data() {
    return {
        tabs: [{name:'优惠券',isoverdue:1}, {name:'失效劵',isoverdue:0},],
        tabIndex: 0,
        couponlist:[],
        currentPage: 1, // 当前页
        total:0,
        pageSize:12,
        loading: false,
        conponType:1,
    };
  },
  computed: {
    paginationProps () {
      const _this = this
      return {
        // showQuickJumper: true,
        pageSize: _this.pageSize,
        total: _this.total,
        onChange (page, pageSize) {
            console.log(page,pageSize)
            _this.loading=true
          _this.currentPage = page
          _this.tabChange()
        }
      }
    },

  },
  mounted(){
       this.tabChange();
  },
  methods:{
      tabChange(){
          this.loading=true
          console.log(this.tabIndex)
          this.conponType=this.tabIndex
             getCouponlist({
        pageSize: this.pageSize,
        pageNo:this.currentPage,
        isoverdue:this.tabIndex==1?0:1
        // type:1,

   
      }).then(({ data }) => {
          this.loading=false
          this.total=parseInt(data.total)
        this.couponlist=data.data
      });
      },
      jumpCoupon(){
       this.$router.push({ path: "/manage/buycoupon" });
   }
  }
};
</script>

<style lang="less" scoped>
h2{
    border-bottom: 1px solid #ddd;
    padding-bottom:20px;
    strong{
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 3px;
            width: 120px;
            left: 0;
            bottom: -25px;
            background-image: linear-gradient(to right, #0049ee , #36bcf0);
    
        }
    }
}
.JumpCoupon{
    background: #0052fe;
    color: #fff;
    padding: 2px 10px;
    border-radius: 4px;
    transition:all .4s;
    cursor: pointer;
    .iconyouhuiquan{
        margin-right: 10px;
    }
    &:hover{
        background: #3271f1;
    }
}
.coupon-List{
    width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
}
</style>